 form {
 display: flex;
 flex-wrap: wrap;
 column-gap: 5%;
 row-gap: 50px;
 font-weight: bold;
 padding-left: 50px;
 font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
} 

.box {
  display: grid;
  width: 20%;
  grid-template-rows: auto;
  row-gap: 10px;
}

.submitButton{
display: flex;
flex-wrap: wrap;
justify-content: center;
margin-bottom: 40px !important;
}

h1 {
  color: aliceblue;
padding: 20px;
background-color: cornflowerblue;
margin-top: 0%;
}